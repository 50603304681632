var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "l-checkout__row" },
    [
      _c(
        "div",
        { staticClass: "l-checkout__leftpane" },
        [
          _vm._t("leftpane"),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "o-form__item o-form__item--floating" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.shippingAddress.businessName,
                  expression: "shippingAddress.businessName"
                }
              ],
              class: { filled: _vm.shippingAddress.businessName },
              attrs: {
                type: "text",
                id: "checkoutBusinessName",
                name: "shippingAddress[businessName]",
                required: "required"
              },
              domProps: { value: _vm.shippingAddress.businessName },
              on: {
                blur: _vm.handleBlur,
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.shippingAddress,
                    "businessName",
                    $event.target.value
                  )
                }
              }
            }),
            _vm._v(" "),
            _c("label", { attrs: { for: "checkoutBusinessName" } }, [
              _vm._v("Virksomhed")
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "o-form__column-2" }, [
            _c(
              "div",
              { staticClass: "o-form__item o-form__item--floating" },
              [
                _c("v-select", {
                  attrs: {
                    options: _vm.enabledCountries,
                    reduce: function(label) {
                      return label.code
                    }
                  },
                  on: { "search:blur": _vm.handleBlur },
                  model: {
                    value: _vm.shippingAddress.countryId,
                    callback: function($$v) {
                      _vm.$set(_vm.shippingAddress, "countryId", $$v)
                    },
                    expression: "shippingAddress.countryId"
                  }
                }),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    class: { filled: _vm.shippingAddress.countryId },
                    attrs: { for: "checkoutBusinessName" }
                  },
                  [_vm._v("Land")]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "o-form__item o-form__item--floating" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.shippingAddress.businessTaxId,
                    expression: "shippingAddress.businessTaxId"
                  }
                ],
                class: { filled: _vm.shippingAddress.businessTaxId },
                attrs: {
                  type: "text",
                  id: "checkoutVatNumber",
                  name: "shippingAddress[businessTaxId]",
                  required: "required"
                },
                domProps: { value: _vm.shippingAddress.businessTaxId },
                on: {
                  blur: _vm.handleBlur,
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.shippingAddress,
                      "businessTaxId",
                      $event.target.value
                    )
                  }
                }
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "checkoutVatNumber" } }, [
                _vm._v("CVR-nr.")
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "o-form__item o-form__item--floating" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.shippingAddress.address1,
                  expression: "shippingAddress.address1"
                }
              ],
              class: { filled: _vm.shippingAddress.address1 },
              attrs: {
                type: "text",
                id: "checkoutAddress1",
                name: "shippingAddress[address1]",
                required: "required"
              },
              domProps: { value: _vm.shippingAddress.address1 },
              on: {
                blur: _vm.handleBlur,
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.shippingAddress, "address1", $event.target.value)
                }
              }
            }),
            _vm._v(" "),
            _c("label", { attrs: { for: "checkoutAddress1" } }, [
              _vm._v("Adresse")
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "o-form__column-2" }, [
            _c("div", { staticClass: "o-form__item o-form__item--floating" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.shippingAddress.zipCode,
                    expression: "shippingAddress.zipCode"
                  }
                ],
                class: { filled: _vm.shippingAddress.zipCode },
                attrs: {
                  type: "text",
                  id: "checkoutZipCode",
                  name: "shippingAddress[zipCode]",
                  required: "required"
                },
                domProps: { value: _vm.shippingAddress.zipCode },
                on: {
                  blur: _vm.handleBlur,
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.shippingAddress,
                      "zipCode",
                      $event.target.value
                    )
                  }
                }
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "checkoutZipCode" } }, [
                _vm._v("Postnummer")
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "o-form__item o-form__item--floating" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.shippingAddress.city,
                    expression: "shippingAddress.city"
                  }
                ],
                class: { filled: _vm.shippingAddress.city },
                attrs: {
                  type: "text",
                  id: "checkoutCity",
                  name: "shippingAddress[city]",
                  required: "required"
                },
                domProps: { value: _vm.shippingAddress.city },
                on: {
                  blur: _vm.handleBlur,
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.shippingAddress, "city", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "checkoutCity" } }, [_vm._v("By")])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "o-form__column-2" }, [
            _c("div", { staticClass: "o-form__item o-form__item--floating" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.shippingAddress.phone,
                    expression: "shippingAddress.phone"
                  }
                ],
                class: { filled: _vm.shippingAddress.phone },
                attrs: {
                  type: "text",
                  id: "checkoutPhone",
                  name: "shippingAddress[phone]",
                  required: "required"
                },
                domProps: { value: _vm.shippingAddress.phone },
                on: {
                  blur: _vm.handleBlur,
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.shippingAddress, "phone", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "checkoutPhone" } }, [
                _vm._v("Telefon")
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "o-form__item o-form__item--floating" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.email,
                    expression: "email"
                  }
                ],
                class: { filled: _vm.email },
                attrs: {
                  type: "email",
                  id: "checkoutEmail",
                  name: "shippingAddress[address1]",
                  required: "required"
                },
                domProps: { value: _vm.email },
                on: {
                  blur: _vm.handleBlur,
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.email = $event.target.value
                  }
                }
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "checkoutEmail" } }, [
                _vm._v("E-mail")
              ])
            ])
          ]),
          _vm._v(" "),
          _vm._m(1),
          _vm._v(" "),
          _c("div", { staticClass: "o-form__column-2" }, [
            _c("div", { staticClass: "o-form__item o-form__item--floating" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.shippingAddress.firstName,
                    expression: "shippingAddress.firstName"
                  }
                ],
                class: { filled: _vm.shippingAddress.firstName },
                attrs: {
                  type: "text",
                  id: "checkoutFirstName",
                  name: "shippingAddress[address1]",
                  required: "required"
                },
                domProps: { value: _vm.shippingAddress.firstName },
                on: {
                  blur: _vm.handleBlur,
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.shippingAddress,
                      "firstName",
                      $event.target.value
                    )
                  }
                }
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "checkoutFirstName" } }, [
                _vm._v("Fornavn")
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "o-form__item o-form__item--floating" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.shippingAddress.lastName,
                    expression: "shippingAddress.lastName"
                  }
                ],
                class: { filled: _vm.shippingAddress.lastName },
                attrs: {
                  type: "text",
                  id: "checkoutLastName",
                  name: "shippingAddress[lastname]",
                  required: "required"
                },
                domProps: { value: _vm.shippingAddress.lastName },
                on: {
                  blur: _vm.handleBlur,
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.shippingAddress,
                      "lastName",
                      $event.target.value
                    )
                  }
                }
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "checkoutLastName" } }, [
                _vm._v("Efternavn")
              ])
            ])
          ]),
          _vm._v(" "),
          _vm.showCoupon
            ? _c("div", { staticClass: "l-checkout__coupon" }, [
                _c(
                  "div",
                  { staticClass: "o-form__item o-form__item--floating" },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.couponCode,
                          expression: "couponCode"
                        }
                      ],
                      class: { filled: _vm.couponCode },
                      attrs: {
                        type: "text",
                        id: "couponCode",
                        name: "couponCode",
                        required: "required"
                      },
                      domProps: { value: _vm.couponCode },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.couponCode = $event.target.value
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("label", { attrs: { for: "couponCode" } }, [
                      _vm._v("Indtast rabatkode")
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "o-btn o-btn--filled",
                    on: {
                      click: function($event) {
                        return _vm.applyCoupon()
                      }
                    }
                  },
                  [_vm._v("\n        Anvend rabatkode\n      ")]
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "o-form__column-2 o-form__column-2--wrap" },
            [
              _c("div", { staticClass: "o-form__item" }, [
                !_vm.showCoupon
                  ? _c(
                      "span",
                      {
                        staticClass: "couponLink",
                        on: {
                          click: function($event) {
                            _vm.showCoupon = true
                          }
                        }
                      },
                      [_vm._v("Jeg er medlem af et branchenetværk")]
                    )
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "o-form__item o-form__item--tlr hideMobile" },
                [
                  _c("div", { staticClass: "o-checkbox" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.acceptTerms,
                          expression: "acceptTerms"
                        }
                      ],
                      attrs: {
                        type: "checkbox",
                        id: "acceptTerms",
                        name: "acceptTerms",
                        required: "required"
                      },
                      domProps: {
                        checked: Array.isArray(_vm.acceptTerms)
                          ? _vm._i(_vm.acceptTerms, null) > -1
                          : _vm.acceptTerms
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.acceptTerms,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.acceptTerms = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.acceptTerms = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.acceptTerms = $$c
                          }
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "o-checkbox__label",
                        attrs: { for: "acceptTerms" }
                      },
                      [
                        _c(
                          "svg",
                          {
                            attrs: {
                              width: "18px",
                              height: "18px",
                              viewbox: "0 0 18 18"
                            }
                          },
                          [
                            _c("path", {
                              attrs: {
                                d:
                                  "M1,9 L1,3.5 C1,2 2,1 3.5,1 L14.5,1 C16,1 17,2 17,3.5 L17,14.5 C17,16 16,17 14.5,17 L3.5,17 C2,17 1,16 1,14.5 L1,9 Z"
                              }
                            }),
                            _vm._v(" "),
                            _c("polyline", {
                              attrs: { points: "1 9 7 14 15 4" }
                            })
                          ]
                        ),
                        _vm._v(" "),
                        _c("span", { staticClass: "o-checkbox__label-value" }, [
                          _vm._v(
                            "\n              Jeg accepterer\n              "
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "l-checkout__terms",
                              on: {
                                click: function($event) {
                                  _vm.showTerms = true
                                }
                              }
                            },
                            [_vm._v("handelsbetingelserne")]
                          )
                        ])
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "form",
                    {
                      attrs: { method: "post" },
                      on: {
                        submit: function($event) {
                          return _vm.continueToPayment()
                        }
                      }
                    },
                    [
                      _vm._t("paymentform"),
                      _vm._v(" "),
                      _c("input", {
                        attrs: {
                          type: "hidden",
                          name: "registerUserOnOrderComplete",
                          value: "true"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "o-form__actions",
                          class: { loading: _vm.isLoading }
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "o-btn toPayment o-btn--filled",
                              attrs: { type: "submit" }
                            },
                            [
                              _vm._v(
                                "\n              Fortsæt til betaling\n            "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _vm.isLoading
                            ? _c("div", { staticClass: "o-loaderInline" }, [
                                _c("div"),
                                _vm._v(" "),
                                _c("div"),
                                _vm._v(" "),
                                _c("div"),
                                _vm._v(" "),
                                _c("div")
                              ])
                            : _vm._e()
                        ]
                      )
                    ],
                    2
                  )
                ]
              )
            ]
          )
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "l-checkout__rightpane" },
        [
          _vm.isLoading
            ? _c("div", { staticClass: "o-loader" }, [_vm._m(2)])
            : _vm._e(),
          _vm._v(" "),
          _vm._t("cart"),
          _vm._v(" "),
          _vm.cart.totalDiscount
            ? _c("div", { staticClass: "l-checkout__discountline" }, [
                _c("div", [_vm._v("Rabat")]),
                _vm._v(" "),
                _c("div", {
                  domProps: { innerHTML: _vm._s(_vm.totalDiscount) }
                })
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "l-checkout__vatline" }, [
            _c("div", [_vm._v("Moms")]),
            _vm._v(" "),
            _c("div", { domProps: { innerHTML: _vm._s(_vm.totalTax) } })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "l-checkout__ordertotal" }, [
            _c("div", [_vm._v("Pris i alt pr. måned")]),
            _vm._v(" "),
            _c("div", {
              staticClass: "value",
              domProps: { innerHTML: _vm._s(_vm.cartTotal) }
            })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "l-checkout__periodetotal" }, [
            _c("div", [_vm._v("Total for abonnementsperioden")]),
            _vm._v(" "),
            _c("div", {
              staticClass: "value",
              domProps: { innerHTML: _vm._s(_vm.periodeTotal) }
            })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "l-checkout__tilbetaling" }, [
            _c("div", [_vm._v("Til betaling nu (VIN-opslag i prøveperiode)")]),
            _vm._v(" "),
            _c("div", {
              staticClass: "value",
              domProps: { innerHTML: _vm._s(_vm.vinPrice) }
            })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "o-form__column showMobile" }, [
            _c("div", { staticClass: "o-form__item o-form__item--tlr" }, [
              _c("div", { staticClass: "o-checkbox" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.acceptTerms,
                      expression: "acceptTerms"
                    }
                  ],
                  attrs: {
                    type: "checkbox",
                    id: "acceptTerms",
                    name: "acceptTerms",
                    required: "required"
                  },
                  domProps: {
                    checked: Array.isArray(_vm.acceptTerms)
                      ? _vm._i(_vm.acceptTerms, null) > -1
                      : _vm.acceptTerms
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.acceptTerms,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.acceptTerms = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.acceptTerms = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.acceptTerms = $$c
                      }
                    }
                  }
                }),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass: "o-checkbox__label",
                    attrs: { for: "acceptTerms" }
                  },
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          width: "18px",
                          height: "18px",
                          viewbox: "0 0 18 18"
                        }
                      },
                      [
                        _c("path", {
                          attrs: {
                            d:
                              "M1,9 L1,3.5 C1,2 2,1 3.5,1 L14.5,1 C16,1 17,2 17,3.5 L17,14.5 C17,16 16,17 14.5,17 L3.5,17 C2,17 1,16 1,14.5 L1,9 Z"
                          }
                        }),
                        _vm._v(" "),
                        _c("polyline", { attrs: { points: "1 9 7 14 15 4" } })
                      ]
                    ),
                    _vm._v(" "),
                    _c("span", { staticClass: "o-checkbox__label-value" }, [
                      _vm._v(
                        "\n              Jeg accepterer eflows\n              "
                      ),
                      _c(
                        "span",
                        {
                          on: {
                            click: function($event) {
                              _vm.showTerms = true
                            }
                          }
                        },
                        [_vm._v("handelsbetingelser")]
                      )
                    ])
                  ]
                )
              ]),
              _vm._v(" "),
              _c(
                "form",
                {
                  attrs: { method: "post" },
                  on: {
                    submit: function($event) {
                      return _vm.continueToPayment()
                    }
                  }
                },
                [
                  _vm._t("paymentform"),
                  _vm._v(" "),
                  _c("input", {
                    attrs: {
                      type: "hidden",
                      name: "registerUserOnOrderComplete",
                      value: "true"
                    }
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "o-form__actions" }, [
                    _c(
                      "button",
                      {
                        staticClass: "o-btn toPayment o-btn--filled",
                        attrs: { type: "submit" }
                      },
                      [
                        _vm._v(
                          "\n              Fortsæt til betaling\n            "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm.isLoading
                      ? _c("div", { staticClass: "o-loaderInline" }, [
                          _c("div"),
                          _vm._v(" "),
                          _c("div"),
                          _vm._v(" "),
                          _c("div"),
                          _vm._v(" "),
                          _c("div")
                        ])
                      : _vm._e()
                  ])
                ],
                2
              )
            ])
          ]),
          _vm._v(" "),
          _vm._t("cta")
        ],
        2
      ),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.showTerms
          ? _c("div", {
              staticClass: "l-checkout__box customscroll",
              domProps: { innerHTML: _vm._s(_vm.terms) }
            })
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.showTerms
          ? _c("div", {
              staticClass: "l-checkout__box-bg",
              on: {
                click: function($event) {
                  _vm.showTerms = false
                }
              }
            })
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.showTerms
          ? _c(
              "div",
              {
                staticClass: "l-checkout__box-close",
                on: {
                  click: function($event) {
                    _vm.showTerms = false
                  }
                }
              },
              [_c("img", { attrs: { src: "/images/close.svg" } })]
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "o-form__item o-form__item--floating" }, [
      _c("h3", [_vm._v("Hvem skal licensen udstedes til?")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "o-form__item responsible-label" }, [
      _c("label", [_vm._v("Ansvarlig")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "o-loader__inner" }, [
      _c("div", { staticClass: "o-loader__spinner-wrapper" }, [
        _c("div", { staticClass: "o-loader__spinner" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }