var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "l-account__wrapper" }, [
    _c("div", { staticClass: "l-account__message" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "l-account__message-text" }, [
        _c(
          "div",
          {
            staticClass: "l-account__message-text l-account__message-text--bold"
          },
          [_vm._v("\n        Abonnementet fornyes automatisk\n      ")]
        ),
        _vm._v(" "),
        _vm.isActive
          ? _c("div", { staticClass: "l-account__message-text--sub" }, [
              _vm._v("\n        Næste betaling\n        "),
              _c("span", { staticClass: "nowrap" }, [
                _vm._v(
                  "d. " +
                    _vm._s(_vm.formatDate(_vm.subscriptiondata.nextPayment)) +
                    "\n        "
                )
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        !_vm.isActive
          ? _c("div", { staticClass: "l-account__message-text--sub" }, [
              _vm._v("\n        Udløber\n        "),
              _c("span", { staticClass: "nowrap" }, [
                _vm._v(
                  "d. " +
                    _vm._s(
                      _vm.formatDate(_vm.subscriptiondata.subscriptionEndDate)
                    )
                )
              ])
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "l-account__message-switch" }, [
        _vm._v("\n      " + _vm._s(_vm.statusLabel) + "\n      "),
        _c(
          "label",
          {
            staticClass: "o-switch",
            on: {
              click: function($event) {
                return _vm.changeStatus($event)
              }
            }
          },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.isActive,
                  expression: "isActive"
                }
              ],
              attrs: { type: "checkbox", readonly: "readonly" },
              domProps: {
                checked: Array.isArray(_vm.isActive)
                  ? _vm._i(_vm.isActive, null) > -1
                  : _vm.isActive
              },
              on: {
                change: function($event) {
                  var $$a = _vm.isActive,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.isActive = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.isActive = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.isActive = $$c
                  }
                }
              }
            }),
            _vm._v(" "),
            _c("span", { staticClass: "slider round" })
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "l-account__box l-account__subscription" },
      [
        _c("h3", [_vm._v("Abonnementsperiode")]),
        _vm._v(" "),
        !_vm.canChangePlan
          ? _c("div", { staticClass: "l-account__subscription-notice" }, [
              _vm._v(
                "\n      Perioden kan ændres 30 dage før udløb (" +
                  _vm._s(
                    _vm.formatDate(_vm.subscriptiondata.subscriptionEdit)
                  ) +
                  ")\n    "
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("vue-slider", {
          attrs: {
            data: _vm.sliderData,
            "data-value": "id",
            "data-label": "name",
            disabled: _vm.isSliderDisabled,
            tooltip: "none"
          },
          scopedSlots: _vm._u([
            {
              key: "label",
              fn: function(ref) {
                var label = ref.label
                return [
                  _c(
                    "div",
                    {
                      class: [
                        "vue-slider-mark-label",
                        { selected: _vm.isSelected(label) }
                      ]
                    },
                    [_vm._v("\n          " + _vm._s(label) + "\n        ")]
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.selectedPlanId,
            callback: function($$v) {
              _vm.selectedPlanId = $$v
            },
            expression: "selectedPlanId"
          }
        }),
        _vm._v(" "),
        _c("v-select", {
          attrs: {
            options: _vm.sliderData,
            label: "name",
            reduce: function(label) {
              return label.id
            },
            placeholder: "Vælg Abonnementsperiode"
          },
          model: {
            value: _vm.selectedPlanId,
            callback: function($$v) {
              _vm.selectedPlanId = $$v
            },
            expression: "selectedPlanId"
          }
        }),
        _vm._v(" "),
        _c("div", { staticClass: "b-estimationCalculator__monthlyPrice" }, [
          _vm._v("\n      " + _vm._s(_vm.licensePrice()) + " DKK/md\n    ")
        ]),
        _vm._v(" "),
        _c("h3", { staticClass: "b-estimationCalculator--marginTop" }, [
          _vm._v("Beregninger pr. måned")
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "b-estimationCalculator__spinner" },
          [
            _c("label", [
              _vm._v("Antal VIN-opslag pr. måned\n        "),
              _c(
                "span",
                {
                  on: {
                    click: function($event) {
                      return _vm.showInfoBox()
                    }
                  }
                },
                [_c("img", { attrs: { src: "/images/info.svg" } })]
              )
            ]),
            _vm._v(" "),
            _c("qty-spinner", {
              attrs: { value: _vm.vinQty, min: 0, disabled: _vm.disabled },
              on: { input: _vm.changeQty },
              model: {
                value: _vm.vinQty,
                callback: function($$v) {
                  _vm.vinQty = $$v
                },
                expression: "vinQty"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "b-estimationCalculator__monthlyPrice" }, [
          _vm._v("\n      " + _vm._s(_vm.vinPrice()) + " DKK/md\n    ")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "l-account__actions" }, [
          _vm.disabled
            ? _c(
                "div",
                {
                  staticClass: "l-account__actions-edit",
                  on: {
                    click: function($event) {
                      return _vm.editSubscription()
                    }
                  }
                },
                [
                  _c("img", { attrs: { src: "/images/edit.svg" } }),
                  _vm._v(" "),
                  _c("span", [_vm._v("Rediger")])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.disabled
            ? _c("div", { staticClass: "l-account__actions-save" }, [
                _c(
                  "button",
                  {
                    staticClass: "transparent",
                    on: {
                      click: function($event) {
                        return _vm.cancelEdit()
                      }
                    }
                  },
                  [_vm._v("Annuller")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    on: {
                      click: function($event) {
                        return _vm.updateSubscription()
                      }
                    }
                  },
                  [_vm._v("Opdater abonnement")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "text" }, [
                  _c("p", [
                    _vm._v(
                      "\n            Ændringer i antal VIN-opslag træder først i kraft fra næste\n            betaling,\n            "
                    ),
                    _c("span", { staticClass: "nowrap" }, [
                      _vm._v(
                        "d. " +
                          _vm._s(
                            _vm.formatDate(_vm.subscriptiondata.nextPayment)
                          ) +
                          "\n            "
                      )
                    ])
                  ])
                ])
              ])
            : _vm._e()
        ])
      ],
      1
    ),
    _vm._v(" "),
    _vm.showConfirmBox
      ? _c("div", { staticClass: "l-account__confirmbox" }, [
          _vm.isActive
            ? _c("div", [
                _c("h3", [_vm._v("Bekræft opsigelse af abonnement")]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "\n        Er du sikker på at du vil opsige dit abonnement\n        "
                  ),
                  _c("span", { staticClass: "nowrap" }, [
                    _vm._v(
                      "fra d.\n          " +
                        _vm._s(
                          _vm.formatDate(
                            _vm.subscriptiondata.subscriptionEndDate
                          )
                        ) +
                        " "
                    )
                  ]),
                  _vm._v("?\n      ")
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.isActive
            ? _c("div", [
                _c("h3", [_vm._v("Bekræft aktivering af abonnement")]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    '\n        Ved at klikke på "Aktiver abonnement" herunder, vil dit SilverDAT 3\n        Pro abonnement blive genoptaget fra dags dato. Vær dog opmærksom på at\n        der kan gå op til 3 hverdage før din konto bliver genaktiveret.\n      '
                  )
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "Du kan altid deaktivere dit abonnement på et senere tidspunkt."
                  )
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "l-account__confirmbox-actions" }, [
            _c(
              "button",
              {
                staticClass: "transparent",
                on: {
                  click: function($event) {
                    _vm.showConfirmBox = false
                  }
                }
              },
              [_vm._v("\n        Annuller\n      ")]
            ),
            _vm._v(" "),
            _vm.isActive
              ? _c(
                  "button",
                  {
                    on: {
                      click: function($event) {
                        return _vm.deactivateSubscription()
                      }
                    }
                  },
                  [_vm._v("\n        Opsig abonnement\n      ")]
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.isActive
              ? _c(
                  "button",
                  {
                    on: {
                      click: function($event) {
                        return _vm.activateSubscription()
                      }
                    }
                  },
                  [_vm._v("\n        Aktiver abonnement\n      ")]
                )
              : _vm._e()
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.showConfirmBox
      ? _c("div", { staticClass: "l-account__confirmbox-bg" })
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "l-account__message-icon" }, [
      _c("img", { attrs: { src: "/images/renewal.svg" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }