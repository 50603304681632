
<template>
  <div class="l-account__wrapper">
    <div class="l-account__message">
      <div class="l-account__message-icon">
        <img src="/images/renewal.svg" />
      </div>
      <div class="l-account__message-text">
        <div class="l-account__message-text l-account__message-text--bold">
          Abonnementet fornyes automatisk
        </div>
        <div class="l-account__message-text--sub" v-if="isActive">
          Næste betaling
          <span class="nowrap"
            >d. {{ formatDate(subscriptiondata.nextPayment) }}
          </span>
        </div>
        <div class="l-account__message-text--sub" v-if="!isActive">
          Udløber
          <span class="nowrap"
            >d. {{ formatDate(subscriptiondata.subscriptionEndDate) }}</span
          >
        </div>
      </div>
      <div class="l-account__message-switch">
        {{ statusLabel }}
        <label class="o-switch" @click="changeStatus($event)">
          <input type="checkbox" readonly="readonly" v-model="isActive" />
          <span class="slider round"></span>
        </label>
      </div>
    </div>
    <div class="l-account__box l-account__subscription">
      <h3>Abonnementsperiode</h3>
      <div class="l-account__subscription-notice" v-if="!canChangePlan">
        Perioden kan ændres 30 dage før udløb ({{
          formatDate(subscriptiondata.subscriptionEdit)
        }})
      </div>
      <vue-slider
        v-model="selectedPlanId"
        :data="sliderData"
        :data-value="'id'"
        :data-label="'name'"
        :disabled="isSliderDisabled"
        :tooltip="'none'"
      >
        <template v-slot:label="{ label }">
          <div
            :class="['vue-slider-mark-label', { selected: isSelected(label) }]"
          >
            {{ label }}
          </div>
        </template>
      </vue-slider>
      <v-select
        :options="sliderData"
        v-model="selectedPlanId"
        label="name"
        :reduce="(label) => label.id"
        placeholder="Vælg Abonnementsperiode"
      ></v-select>
      <div class="b-estimationCalculator__monthlyPrice">
        {{ licensePrice() }} DKK/md
      </div>

      <h3 class="b-estimationCalculator--marginTop">Beregninger pr. måned</h3>

      <div class="b-estimationCalculator__spinner">
        <label
          >Antal VIN-opslag pr. måned
          <span @click="showInfoBox()"><img src="/images/info.svg" /></span
        ></label>
        <qty-spinner
          :value="vinQty"
          :min="0"
          :disabled="disabled"
          @input="changeQty"
          v-model="vinQty"
        ></qty-spinner>
      </div>
      <div class="b-estimationCalculator__monthlyPrice">
        {{ vinPrice() }} DKK/md
      </div>
      <div class="l-account__actions">
        <div
          class="l-account__actions-edit"
          @click="editSubscription()"
          v-if="disabled"
        >
          <img src="/images/edit.svg" /> <span>Rediger</span>
        </div>
        <div class="l-account__actions-save" v-if="!disabled">
          <button @click="cancelEdit()" class="transparent">Annuller</button>
          <button @click="updateSubscription()">Opdater abonnement</button>
          <div class="text">
            <p>
              Ændringer i antal VIN-opslag træder først i kraft fra næste
              betaling,
              <span class="nowrap"
                >d. {{ formatDate(subscriptiondata.nextPayment) }}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="l-account__confirmbox" v-if="showConfirmBox">
      <div v-if="isActive">
        <h3>Bekræft opsigelse af abonnement</h3>
        <p>
          Er du sikker på at du vil opsige dit abonnement
          <span class="nowrap"
            >fra d.
            {{ formatDate(subscriptiondata.subscriptionEndDate) }} </span
          >?
        </p>
      </div>
      <div v-if="!isActive">
        <h3>Bekræft aktivering af abonnement</h3>
        <p>
          Ved at klikke på "Aktiver abonnement" herunder, vil dit SilverDAT 3
          Pro abonnement blive genoptaget fra dags dato. Vær dog opmærksom på at
          der kan gå op til 3 hverdage før din konto bliver genaktiveret.
        </p>
        <p>Du kan altid deaktivere dit abonnement på et senere tidspunkt.</p>
      </div>
      <div class="l-account__confirmbox-actions">
        <button class="transparent" @click="showConfirmBox = false">
          Annuller
        </button>
        <button @click="deactivateSubscription()" v-if="isActive">
          Opsig abonnement
        </button>
        <button @click="activateSubscription()" v-if="!isActive">
          Aktiver abonnement
        </button>
      </div>
    </div>
    <div class="l-account__confirmbox-bg" v-if="showConfirmBox"></div>
  </div>
</template>

<script>
import QtySpinner from "./QtySpinner.vue";
import VueSlider from "vue-slider-component";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import axios from "axios";

export default {
  name: "subscription-manager",
  components: {
    QtySpinner,
    VueSlider,
    vSelect,
  },
  data: function () {
    return {
      selectedPlanId: this.subscriptiondata.selectedPlanId,
      displayPrMonth: true,
      infoboxVisible: false,
      isCanceled: parseInt(this.subscriptiondata.subscription.isCanceled),
      vinQty: this.subscriptiondata.vin.qty,
      subscription: this.subscriptiondata.subscription,
      disabled: true,
      showConfirmBox: false,
      orgSelectedPlan: 0,
      orgVinQty: 0,
    };
  },
  props: {
    subscriptiondata: Object,
    csrf: String,
  },
  computed: {
    canChangePlan() {
      if (
        this.subscriptiondata.nextPayment ==
        this.subscriptiondata.subscriptionEndDate
      ) {
        return true;
      }

      return false;
    },
    isSliderDisabled() {
      if (this.disabled) {
        return true;
      }

      if (!this.canChangePlan) {
        return true;
      }
      return false;
    },
    statusLabel() {
      if (this.isActive) {
        return "Aktiveret";
      }

      return "Deaktiveret";
    },
    isActive() {
      if (this.isCanceled) {
        return false;
      }

      return true;
    },
    sliderData() {
      let data = [];
      for (var i = 0; i < this.subscriptiondata.plans.length; i++) {
        let plan = {
          id: this.subscriptiondata.plans[i].id,
          name: this.subscriptiondata.plans[i].title,
        };
        data.push(plan);
      }

      return data;
    },
    selectedPlan() {
      let plan = this.subscriptiondata.plans.filter(
        (p) => p["id"] == this.selectedPlanId
      );
      return plan[0];
    },
    hasChanges() {
      if (this.subscriptiondata.selectedPlanId !== this.selectedPlanId) {
        return true;
      }

      if (this.subscriptiondata.vin.qty !== this.vinQty) {
        return true;
      }

      return false;
    },
  },
  methods: {
    formatDate(date) {
      var d = new Date(date);
      return d.toLocaleDateString(this.subscriptiondata.locale, {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
    editSubscription() {
      (this.orgSelectedPlan = this.selectedPlanId),
        (this.orgVinQty = this.vinQty);
      this.disabled = false;
    },
    cancelEdit() {
      this.selectedPlanId = this.orgSelectedPlan;
      this.vinQty = this.orgVinQty;
      this.disabled = true;
    },
    changeStatus(e) {
      e.preventDefault();
      this.showConfirmBox = true;
    },
    deactivateSubscription() {
      const formData = new FormData();
      formData.append("action", "quantity-extensions/subscription/deactivate");
      formData.append("CRAFT_CSRF_TOKEN", this.csrf);
      axios
        .post(document.location.href, formData)
        .then((response) => (this.isCanceled = true))
        .catch((error) => {});

      this.showConfirmBox = false;
    },
    activateSubscription() {
      const formData = new FormData();
      formData.append("action", "quantity-extensions/subscription/reactivate");
      formData.append("CRAFT_CSRF_TOKEN", this.csrf);
      axios
        .post(document.location.href, formData)
        .then((response) => (this.isCanceled = false))
        .catch((error) => {});

      this.showConfirmBox = false;
    },
    showInfoBox() {
      this.infoboxVisible = true;
    },
    hideInfobox() {
      this.infoboxVisible = false;
    },
    vinPrice() {
      let floatVal = this.subscriptiondata.vin.price * this.vinQty;

      if (floatVal % 1 !== 0) {
        // Cast to string and return the last two numbers.
        floatVal = parseFloat(
          (this.subscriptiondata.vin.price * this.vinQty).toFixed(2)
        );
      }

      return floatVal;
    },
    licensePrice() {
      return this.selectedPlan.price / 1;
    },
    changeQty(value) {
      this.vinQty = value;
    },
    isSelected(label) {
      return this.selectedPlan.title === label;
    },
    updateSubscription() {
      if (!this.hasChanges) {
        this.disabled = true;
        return;
      }

      const formData = new FormData();
      formData.append("action", "quantity-extensions/subscription/update");
      formData.append("CRAFT_CSRF_TOKEN", this.csrf);
      formData.append("planId", this.selectedPlanId);
      formData.append("isActive", this.isActive);
      formData.append("vinQty", this.vinQty);
      axios
        .post(document.location.href, formData)
        .then((response) => (this.disabled = true))
        .catch((error) => {});
    },
  },
  watch: {},
};
</script>

<style lang="scss">
</style>
