var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    {
      attrs: { name: "fade" },
      on: {
        "after-enter": function($event) {
          _vm.showClose = true
        },
        "after-leave": function($event) {
          _vm.showClose = false
        }
      }
    },
    [
      _vm.show
        ? _c(
            "div",
            {
              staticClass: "inline-youtube-video__popup",
              on: {
                click: function($event) {
                  return _vm.close()
                }
              }
            },
            [
              _c("transition", { attrs: { name: "close" } }, [
                _vm.showClose
                  ? _c(
                      "div",
                      {
                        staticClass: "inline-youtube-video__popup-close",
                        on: {
                          click: function($event) {
                            return _vm.close()
                          }
                        }
                      },
                      [
                        _c(
                          "svg",
                          {
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              width: "28",
                              height: "28",
                              viewBox: "0 0 28 28"
                            }
                          },
                          [
                            _c(
                              "g",
                              {
                                attrs: {
                                  fill: "#DE3C4B",
                                  "fill-rule": "evenodd"
                                }
                              },
                              [
                                _c("rect", {
                                  attrs: {
                                    width: "28",
                                    height: "2",
                                    y: "13",
                                    rx: "1",
                                    transform: "rotate(45 14 14)"
                                  }
                                }),
                                _vm._v(" "),
                                _c("rect", {
                                  attrs: {
                                    width: "28",
                                    height: "2",
                                    y: "13",
                                    rx: "1",
                                    transform: "rotate(135 14 14)"
                                  }
                                })
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("youtube", {
                attrs: {
                  "video-id": _vm.videoId,
                  "player-vars": _vm.playerVars
                },
                on: {
                  ready: _vm.ready,
                  buffering: _vm.buffering,
                  ended: _vm.ended
                }
              })
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }