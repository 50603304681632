<template>
  <div class="dealersmap">
    <div class="o-loader" v-if="isLoading">
      <div class="o-loader__inner">
        <div class="o-loader__spinner-wrapper">
          <div class="o-loader__spinner"></div>
        </div>
      </div>
    </div>

    <div class="dealersmap__list">
      <div class="dealersmap__filter">
        <div class="dealersmap__list-label">
          {{ translations.SelectCountry }}
        </div>
        <v-select
          :placeholder="translations.SelectCountry"
          :searchable="false"
          :options="selectOptions"
          v-model="selectedOption"
          @input="updateBounds"
        ></v-select>
      </div>

      <div class="dealersmap__dealers customscroll">
        <div
          class="dealersmap__dealer"
          :key="index"
          v-for="(dealer, index) in filteredDealers"
        >
          <div class="dealersmap__dealer-icon">
            <img src="/images/map-marker.svg" />
          </div>
          <div>
            <div class="dealersmap__dealer-title">
              {{ dealer.title }}
            </div>
            <div class="dealersmap__dealer-address">{{ dealer.address }}</div>
            <div class="dealersmap__dealer-phone" v-if="dealer.phone">
              {{ translations.Phone }}: {{ dealer.phone }}
            </div>
            <div class="dealersmap__dealer-email" v-if="dealer.email">
              {{ translations.Email }}:
              <a :href="`mailto:${dealer.email}`" target="_blank">{{
                dealer.email
              }}</a>
            </div>
            <div class="dealersmap__dealer-website" v-if="dealer.website">
              {{ translations.Website }}:
              <a :href="dealer.website" target="_blank">
                {{ simpleUrl(dealer.website) }}
              </a>
            </div>
            <div
              class="dealersmap__dealer-partner"
              v-if="dealer.isSolutionspartner"
            >
              <img src="/images/SolutionPartner.svg" />
            </div>
          </div>
        </div>

        <div class="dealersmap__nodealer" v-if="!filteredDealers.length">
          <div class="o-formmessage o-formmessage--good">
            <div class="o-formmessage__icon">
              <svg
                width="37"
                height="37"
                viewBox="0 0 37 37"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.2266 36.7266C28.1677 36.7266 36.2266 28.6677 36.2266 18.7266C36.2266 8.78544 28.1677 0.726562 18.2266 0.726562C8.28544 0.726562 0.226562 8.78544 0.226562 18.7266C0.226562 28.6677 8.28544 36.7266 18.2266 36.7266Z"
                  fill="#CB3626"
                />
                <path
                  d="M20.2266 9.72656H16.2266V21.7266H20.2266V9.72656Z"
                  fill="white"
                />
                <path
                  d="M20.2266 23.7266H16.2266V27.7266H20.2266V23.7266Z"
                  fill="white"
                />
              </svg>
            </div>
            {{ translations.NoDealer }}
          </div>
        </div>
        <a :href="partnerPage" class="o-btn o-btn--filled" v-if="partnerPage">
          {{ translations.PartnerButton }}
        </a>
      </div>
    </div>

    <div class="dealersmap__map">
      <gmap-map :center="center" :zoom="zoom" :options="mapOptions" ref="map">
        <gmap-marker
          :key="index"
          v-for="(m, index) in filteredMarkers"
          :position="m.position"
          :icon="{ url: '/images/map-marker.svg' }"
          @click="center = m.position"
        ></gmap-marker>
      </gmap-map>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import gql from "graphql-tag";
import axios from "axios";

const countries = require("i18n-iso-countries");

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
countries.registerLocale(require("i18n-iso-countries/langs/da.json"));
countries.registerLocale(require("i18n-iso-countries/langs/sv.json"));
countries.registerLocale(require("i18n-iso-countries/langs/fi.json"));
countries.registerLocale(require("i18n-iso-countries/langs/nn.json"));

export default {
  name: "dealers-map",

  components: {
    vSelect,
  },

  props: {
    language: {
      type: String,
      required: true,
    },
    userCountry: {
      type: String,
    },
    partnerPage: {
      type: String,
    },
    mapMaxZoom: 24,
    apolloData: [],
    translations: {
      type: Object,
      required: true,
    },
  },

  apollo: {
    entries: {
      query: gql`
        query {
          entries(section: "dealers", orderBy: "title") {
            title
            ... on dealers_dealer_Entry {
              address
              country
              email
              phone
              website
              isSolutionspartner
              locations {
                ... on locations_location_BlockType {
                  locationData {
                    lat
                    lng
                    parts {
                      country
                    }
                  }
                }
              }
            }
          }
        }
      `,
      result({ data, loading, networkStatus }) {
        if (!loading) {
          let markers = [];
          let dealers = [];

          Array.from(this.entries).forEach(function (dealer) {
            dealers.push(dealer);

            dealer.locations.forEach(function (location) {
              markers.push({
                country: dealer.country,
                position: {
                  lat: location.locationData.lat,
                  lng: location.locationData.lng,
                },
              });
            });
          });

          this.markers = markers;
          this.dealers = dealers;

          this.updateBounds();
          this.isLoading = false;
        }
      },
    },
  },

  data: () => ({
    isLoading: true,
    entries: [],
    mapOptions: {
      styles: [
        {
          elementType: "geometry",
          stylers: [
            {
              color: "#ecf8ff",
            },
          ],
        },
        {
          elementType: "geometry.stroke",
          stylers: [
            {
              color: "#75848b",
            },
            {
              visibility: "on",
            },
          ],
        },
        {
          elementType: "labels.icon",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#616161",
            },
          ],
        },
        {
          elementType: "labels.text.stroke",
          stylers: [
            {
              color: "#f5f5f5",
            },
          ],
        },
        {
          featureType: "administrative.land_parcel",
          stylers: [
            {
              color: "#ecf8ff",
            },
          ],
        },
        {
          featureType: "administrative.land_parcel",
          elementType: "labels",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "administrative.land_parcel",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#bdbdbd",
            },
          ],
        },
        {
          featureType: "administrative.neighborhood",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "landscape",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#ecf8ff",
            },
          ],
        },
        {
          featureType: "poi",
          elementType: "geometry",
          stylers: [
            {
              color: "#eeeeee",
            },
          ],
        },
        {
          featureType: "poi",
          elementType: "labels.text",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "poi",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#757575",
            },
          ],
        },
        {
          featureType: "poi.business",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "poi.medical",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "poi.park",
          elementType: "geometry",
          stylers: [
            {
              color: "#ecf8ff",
            },
          ],
        },
        {
          featureType: "poi.park",
          elementType: "labels.text",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "poi.park",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#9e9e9e",
            },
          ],
        },
        {
          featureType: "poi.place_of_worship",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "poi.school",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "road",
          elementType: "geometry",
          stylers: [
            {
              color: "#ffffff",
            },
          ],
        },
        {
          featureType: "road.arterial",
          elementType: "labels",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "road.arterial",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#757575",
            },
          ],
        },
        {
          featureType: "road.highway",
          elementType: "geometry",
          stylers: [
            {
              color: "#cae0ed",
            },
          ],
        },
        {
          featureType: "road.highway",
          elementType: "labels",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "road.highway",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#616161",
            },
          ],
        },
        {
          featureType: "road.local",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "road.local",
          elementType: "labels",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "road.local",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#9e9e9e",
            },
          ],
        },
        {
          featureType: "transit.line",
          elementType: "geometry",
          stylers: [
            {
              color: "#cae0ed",
            },
          ],
        },
        {
          featureType: "transit.station",
          elementType: "geometry",
          stylers: [
            {
              color: "#eeeeee",
            },
          ],
        },
        {
          featureType: "water",
          elementType: "geometry",
          stylers: [
            {
              color: "#b8cedb",
            },
          ],
        },
        {
          featureType: "water",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#9e9e9e",
            },
          ],
        },
      ],
      streetViewControl: false,
      mapTypeControl: false,
      fullscreenControl: false,
      maxZoom: 24,
    },
    center: { lat: 55.798435, lng: 10.300165 },
    zoom: 4,
    selectedOption: null,
    dealers: [],
    markers: [],
  }),

  computed: {
    selectOptions() {
      let options = [];
      let codes = [];
      let lang = this.language;

      Array.from(this.entries).forEach(function (dealer) {
        if (!codes.includes(dealer.country)) {
          codes.push(dealer.country);
          options.push({
            label: countries.getName(dealer.country, lang),
            code: dealer.country,
          });
        }
      });

      return options;
    },

    filteredDealers() {
      return this.dealers.filter((dealer) => {
        if (!this.selectedOption) {
          return dealer;
        }

        if (this.selectedOption.code === dealer.country) {
          return dealer;
        }
      });
    },

    filteredMarkers() {
      let markers = this.markers.filter((marker) => {
        if (!this.selectedOption) {
          return marker;
        }

        if (this.selectedOption.code === marker.country) {
          return marker;
        }
      });

      if (markers.length === 0) {
        markers = this.markers;
      }

      return markers;
    },
  },

  methods: {
    __delay: function (timer) {
      return new Promise((resolve) => {
        timer = timer || 2000;
        setTimeout(function () {
          resolve();
        }, timer);
      });
    },
    updateBounds: async function () {
      while (typeof google === "undefined") await this.__delay(1000);

      var bounds = new google.maps.LatLngBounds();
      let markers = this.filteredMarkers;

      for (let marker of markers) {
        bounds.extend(marker.position);
      }

      this.mapOptions.maxZoom = 10;

      if (this.$refs.map) {
        this.$refs.map.fitBounds(bounds);
      }
    },

    simpleUrl(websiteUrl) {
      return websiteUrl.replace(/(^\w+:|^)\/\//, "");
    },
  },

  mounted() {
    if (this.userCountry) {
      this.selectedOption = {
        label: countries.getName(this.userCountry, this.language),
        code: this.userCountry,
      };
    }

    this.$refs.map.$gmapApiPromiseLazy().then(() => {
      this.updateBounds();
    });
  },
};
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";

.vs__dropdown-toggle {
  background: #fff;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 28px;
  padding: 4px 8px 7px;
}

.vs__selected {
  padding: 0;
  color: #0c2c52;
}

.vs__dropdown-option {
  padding: 4px 8px;
}

.vs__open-indicator {
  path {
    fill: #5782b3;
  }
}

.vue-map-container {
  height: 100%;
}

.dealersmap {
  position: relative;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 829px) {
    flex-direction: column;
  }

  &__nodealer {
    text-align: center;
  }

  &__list {
    width: calc(5 / 12 * 100% - 3px);
    height: 778px;
    padding: 51px 0;
    background: #e4ebef;

    @media screen and (max-width: 829px) {
      width: 100%;
      height: 450px;
    }

    &-label {
      margin-bottom: 5px;
      font-weight: bold;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 17px;
    }
  }

  &__map {
    width: calc(7 / 12 * 100% - 3px);
    height: 778px;
    @media screen and (max-width: 829px) {
      width: 100%;
      height: 350px;
    }
  }

  &__filter {
    padding: 0 60px;

    @media screen and (max-width: 999px) {
      padding: 0 30px;
    }
  }

  &__dealers {
    margin-top: 34px;
    height: calc(100% - 66px);
    padding: 0 60px;

    @media screen and (max-width: 999px) {
      padding: 0 30px;
    }
  }

  &__dealer {
    display: flex;
    padding: 41px 0;
    border-bottom: 1px solid #a5bbc8;
    font-size: 18px;
    line-height: 1.55;

    @media screen and (max-width: 1089px) {
      font-size: 16px;
    }

    &:first-child {
      padding-top: 0px;
    }

    &-icon {
      margin-right: 9px;

      img {
        height: 29px;
      }
    }

    &-info {
    }

    &-title {
      font-weight: bold;
    }

    &-address {
      margin-bottom: 28px;
      white-space: pre;
    }

    &-partner {
      margin-top: 22px;
    }
  }

  .o-formmessage {
    display: block;
    margin-top: 18px;
    margin-bottom: 0;
  }

  .o-btn {
    margin-top: 25px;
  }
}
</style>
