<template>
	<transition name='fade' @after-enter="showClose = true" @after-leave="showClose = false">
		<div class="inline-youtube-video__popup" v-if="show" v-on:click="close()">

			<transition name='close'>
				<div class="inline-youtube-video__popup-close" v-if="showClose" v-on:click="close()">
					<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
						<g fill="#DE3C4B" fill-rule="evenodd">
							<rect width="28" height="2" y="13" rx="1" transform="rotate(45 14 14)"/>
							<rect width="28" height="2" y="13" rx="1" transform="rotate(135 14 14)"/>
						</g>
					</svg>
				</div>
			</transition>

			<youtube
				:video-id="videoId"
				:player-vars="playerVars"
				@ready="ready"
				@buffering="buffering"
				@ended="ended"
			/>
		</div>
	</transition>
</template>

<script>
    import {Youtube} from 'vue-youtube'

    export default {
        name: 'youtube-popup',
        components: {
            Youtube
        },
        props: {
            videoId: {
                type: String,
                default: '',
            }
        },

        data: () => ({
            playerVars: {
                autoplay: 0,
                rel: 0,
                showinfo: 0,
            },
            player: null,
            showClose: false,
            show: false
        }),

        computed: {
            hasId: vm => vm.videoId.length > 0
        },

        mounted() {
            this.show = true
        },

        methods: {
            ready(e) {
                this.player = e;
                this.player.playVideo();
            },

            buffering() {
                clearTimeout(this.$options.delayAnimation);
            },

            ended() {
                this.close()
            },

            close() {
                this.$store.commit('closeVideo')
            }
        }
    }
</script>

<style lang="scss">
	.inline-youtube-video {
		display: flex;
		transition: all ease .3s;
		align-items: center;

		&__popup {
			position: fixed;
			z-index: 50;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			background: rgba(12, 44, 82, 0.75);
			transition: opacity .6s;
			opacity: 1;

			&.fade-enter, &.fade-leave-to, &.fade-leave-active {
				opacity: 0;
			}

			&-close {
				z-index: 1000;
				background-color: #fff;
				top: 0;
				right: 0;
				position: absolute;
				width: 80px;
				height: 80px;
				display: flex;
				justify-content: center;
				align-items: center;
				opacity: 1;
				transition: all ease .3s;

				svg{
					g{
						fill: #E0AE42;
					}
				}

				&.close-enter {
					opacity: 0;
					right: -80px;
				}

				&:hover {
					cursor: pointer;
					opacity: .7;
				}
			}


			iframe {
				max-width: 100%;
			}
		}

		&:hover {
			cursor: pointer;

			.inline-youtube-video__play {
				path.bg {
					fill: #c92233;
				}
			}
		}
	}
</style>
