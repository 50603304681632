var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "qtySpinner",
      class: { "qtySpinner--disabled": _vm.disabled }
    },
    [
      _c(
        "span",
        {
          staticClass: "qtySpinner__button",
          on: {
            click: function($event) {
              return _vm.decreaseNumber()
            }
          }
        },
        [_vm._v(" - ")]
      ),
      _vm._v(" "),
      _c("input", {
        staticClass: "qtySpinner__input",
        attrs: { type: "text", min: _vm.min, debounce: "500" },
        domProps: { value: _vm.numericValue },
        on: { keypress: _vm.validateInput, input: _vm.inputValue }
      }),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "qtySpinner__button",
          on: {
            click: function($event) {
              return _vm.increaseNumber()
            }
          }
        },
        [_vm._v(" + ")]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }