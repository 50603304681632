<template>
  <div class="qtySpinner" :class="{'qtySpinner--disabled': disabled}">
    <span @click="decreaseNumber()" class="qtySpinner__button"> - </span>
    <input
      type="text"
      v-bind:value="numericValue"
      @keypress="validateInput"
      @input="inputValue"
      :min="min"
      debounce="500"
      class="qtySpinner__input"
    />
    <span @click="increaseNumber()" class="qtySpinner__button"> + </span>
  </div>
</template>

<script>
export default {
  name: "qty-spinner",
  data: function () {
    return {
      numericValue: this.value,
    };
  },
  props: {
    value: {
      type: Number,
      default: 0,
    },
    min: {
      default: 0,
      type: Number,
    },
    step: {
      default: 10,
      type: Number,
    },
    integerOnly: {
      default: false,
      type: Boolean,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
  },
  methods: {
    increaseNumber() {
		if(!this.disabled){
      		this.numericValue += this.step;
		}
    },
    decreaseNumber() {
      if(!this.disabled){
      		this.numericValue -= this.step;
		}
    },
    isInteger(evt) {
      evt = evt ? evt : window.event;
      let key = evt.keyCode || evt.which;
      key = String.fromCharCode(key);
      const regex = /[0-9]/;
      if (!regex.test(key)) {
        evt.returnValue = false;
        if (evt.preventDefault) evt.preventDefault();
      }
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    validateInput(evt) {
      if (this.integerOnly === true) {
        this.isInteger(evt);
      } else {
        this.isNumber(evt);
      }
    },
    inputValue(evt) {
      this.numericValue = evt.target.value
        ? parseInt(evt.target.value)
        : this.min;
    },
  },
  watch: {
    numericValue: function (val, oldVal) {
      if (val <= this.min) {
        this.numericValue = parseInt(this.min);
      }
      if (val >= this.max) {
        this.numericValue = parseInt(this.max);
      }

      this.$emit("input", val);
    },
  },
};
</script>

<style lang="scss">
.qtySpinner {
  $root: &;

  max-width: 242px;
  display: flex;
  padding: 4px;
  background-color: #e4ebef;

  textarea,
  select,
  input,
  button {
    outline: none;
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 34px;
    width: 70px;
    border-radius: 3px;
    background-color: #ffffff;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
    &:hover {
      cursor: pointer;
    }
    &::selection {
      color: inherit;
      background: none;
    }
  }

  &__input {
    max-width: 92px;
    background: transparent;
    border: none;
    text-align: center;
    color: #0c2c52;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 17px;
  }

  &--disabled{
	#{$root}__button{
		background-color: #E4EBEF;
		&:hover{
			cursor: default;
		}
	}
  }
}
</style>
