import { render, staticRenderFns } from "./FaqList.vue?vue&type=template&id=51fc8b44&"
import script from "./FaqList.vue?vue&type=script&lang=js&"
export * from "./FaqList.vue?vue&type=script&lang=js&"
import style0 from "./FaqList.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/atlassian/pipelines/agent/build/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('51fc8b44')) {
      api.createRecord('51fc8b44', component.options)
    } else {
      api.reload('51fc8b44', component.options)
    }
    module.hot.accept("./FaqList.vue?vue&type=template&id=51fc8b44&", function () {
      api.rerender('51fc8b44', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/website/js/components/FaqList.vue"
export default component.exports