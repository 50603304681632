var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "faqslist" }, [
    _c("div", { staticClass: "faqslist__header" }, [
      _c("h2", [_vm._v(_vm._s(_vm.title))]),
      _vm._v(" "),
      _c("div", { staticClass: "faqslist__filter" }, [
        _c(
          "div",
          {
            staticClass: "faqslist__filter-item",
            class: { active: null == _vm.selectedSolution },
            on: {
              click: function($event) {
                _vm.selectedSolution = null
              }
            }
          },
          [_vm._v("\n        " + _vm._s(_vm.translations.all) + "\n      ")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "faqslist__filter-item",
            class: { active: "workflow" == _vm.selectedSolution },
            on: {
              click: function($event) {
                _vm.selectedSolution = "workflow"
              }
            }
          },
          [_vm._v("\n        " + _vm._s("Workflow") + "\n      ")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "faqslist__filter-item faqslist__filter-item_inventory",
            class: { active: "inventory" == _vm.selectedSolution },
            on: {
              click: function($event) {
                _vm.selectedSolution = "inventory"
              }
            }
          },
          [_vm._v("\n        " + _vm._s("Inventory") + "\n      ")]
        )
      ])
    ]),
    _vm._v(" "),
    _vm.isLoading
      ? _c("div", { staticClass: "o-loader" }, [_vm._m(0)])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "article",
      _vm._l(_vm.filteredFaqs, function(faq, index) {
        return _c(
          "a",
          {
            key: index,
            staticClass: "faqslist__item",
            attrs: { href: faq.url }
          },
          [
            _c("div", { staticClass: "faqslist__item-title" }, [
              _vm._v("\n        " + _vm._s(faq.title) + "\n        "),
              faq.solution
                ? _c(
                    "span",
                    {
                      staticClass: "faqslist__item-label",
                      class: "faqslist__item-label_" + faq.solution
                    },
                    [_vm._v(_vm._s(_vm._f("capitalize")(faq.solution)))]
                  )
                : _vm._e()
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "faqslist__item-link" }, [
              _vm._v(_vm._s(_vm.label))
            ])
          ]
        )
      }),
      0
    ),
    _vm._v(" "),
    _vm.ifMoreToLoad
      ? _c(
          "a",
          {
            staticClass: "o-btn o-btn--filled faqslist__loadmore",
            on: { click: _vm.loadMore }
          },
          [_vm._v(_vm._s(_vm.button))]
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "o-loader__inner" }, [
      _c("div", { staticClass: "o-loader__spinner-wrapper" }, [
        _c("div", { staticClass: "o-loader__spinner" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }