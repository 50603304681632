
<template>
  <div class="l-checkout__row">
    <div class="l-checkout__leftpane">
      <slot name="leftpane"></slot>

      <div class="o-form__item o-form__item--floating">
        <h3>Hvem skal licensen udstedes til?</h3>
      </div>

      <div class="o-form__item o-form__item--floating">
        <input
          type="text"
          id="checkoutBusinessName"
          name="shippingAddress[businessName]"
          required="required"
          :class="{ filled: shippingAddress.businessName }"
          @blur="handleBlur"
          v-model="shippingAddress.businessName"
        />
        <label for="checkoutBusinessName">Virksomhed</label>
      </div>

      <div class="o-form__column-2">
        <div class="o-form__item o-form__item--floating">
          <v-select
            :options="enabledCountries"
            v-model="shippingAddress.countryId"
            :reduce="(label) => label.code"
            @search:blur="handleBlur"
          ></v-select>
          <label
            for="checkoutBusinessName"
            :class="{ filled: shippingAddress.countryId }"
            >Land</label
          >
        </div>
        <div class="o-form__item o-form__item--floating">
          <input
            type="text"
            id="checkoutVatNumber"
            name="shippingAddress[businessTaxId]"
            required="required"
            :class="{ filled: shippingAddress.businessTaxId }"
            @blur="handleBlur"
            v-model="shippingAddress.businessTaxId"
          />
          <label for="checkoutVatNumber">CVR-nr.</label>
        </div>
      </div>
      <div class="o-form__item o-form__item--floating">
        <input
          type="text"
          id="checkoutAddress1"
          name="shippingAddress[address1]"
          required="required"
          :class="{ filled: shippingAddress.address1 }"
          @blur="handleBlur"
          v-model="shippingAddress.address1"
        />
        <label for="checkoutAddress1">Adresse</label>
      </div>
      <div class="o-form__column-2">
        <div class="o-form__item o-form__item--floating">
          <input
            type="text"
            id="checkoutZipCode"
            name="shippingAddress[zipCode]"
            required="required"
            :class="{ filled: shippingAddress.zipCode }"
            @blur="handleBlur"
            v-model="shippingAddress.zipCode"
          />
          <label for="checkoutZipCode">Postnummer</label>
        </div>

        <div class="o-form__item o-form__item--floating">
          <input
            type="text"
            id="checkoutCity"
            name="shippingAddress[city]"
            required="required"
            :class="{ filled: shippingAddress.city }"
            @blur="handleBlur"
            v-model="shippingAddress.city"
          />
          <label for="checkoutCity">By</label>
        </div>
      </div>

      <div class="o-form__column-2">
        <div class="o-form__item o-form__item--floating">
          <input
            type="text"
            id="checkoutPhone"
            name="shippingAddress[phone]"
            required="required"
            :class="{ filled: shippingAddress.phone }"
            @blur="handleBlur"
            v-model="shippingAddress.phone"
          />
          <label for="checkoutPhone">Telefon</label>
        </div>

        <div class="o-form__item o-form__item--floating">
          <input
            type="email"
            id="checkoutEmail"
            name="shippingAddress[address1]"
            required="required"
            :class="{ filled: email }"
            @blur="handleBlur"
            v-model="email"
          />
          <label for="checkoutEmail">E-mail</label>
        </div>
      </div>

      <div class="o-form__item responsible-label">
        <label>Ansvarlig</label>
      </div>

      <div class="o-form__column-2">
        <div class="o-form__item o-form__item--floating">
          <input
            type="text"
            id="checkoutFirstName"
            name="shippingAddress[address1]"
            required="required"
            :class="{ filled: shippingAddress.firstName }"
            @blur="handleBlur"
            v-model="shippingAddress.firstName"
          />
          <label for="checkoutFirstName">Fornavn</label>
        </div>

        <div class="o-form__item o-form__item--floating">
          <input
            type="text"
            id="checkoutLastName"
            name="shippingAddress[lastname]"
            required="required"
            :class="{ filled: shippingAddress.lastName }"
            @blur="handleBlur"
            v-model="shippingAddress.lastName"
          />
          <label for="checkoutLastName">Efternavn</label>
        </div>
      </div>

      <div class="l-checkout__coupon" v-if="showCoupon">
        <div class="o-form__item o-form__item--floating">
          <input
            type="text"
            id="couponCode"
            name="couponCode"
            required="required"
            :class="{ filled: couponCode }"
            v-model="couponCode"
          />
          <label for="couponCode">Indtast rabatkode</label>
        </div>
        <button class="o-btn o-btn--filled" v-on:click="applyCoupon()">
          Anvend rabatkode
        </button>
      </div>

      <div class="o-form__column-2 o-form__column-2--wrap">
        <div class="o-form__item">
          <span
            class="couponLink"
            v-if="!showCoupon"
            v-on:click="showCoupon = true"
            >Jeg er medlem af et branchenetværk</span
          >
        </div>

        <div class="o-form__item o-form__item--tlr hideMobile">
          <div class="o-checkbox">
            <input
              type="checkbox"
              id="acceptTerms"
              name="acceptTerms"
              required="required"
              v-model="acceptTerms"
            />

            <label for="acceptTerms" class="o-checkbox__label">
              <svg width="18px" height="18px" viewbox="0 0 18 18">
                <path
                  d="M1,9 L1,3.5 C1,2 2,1 3.5,1 L14.5,1 C16,1 17,2 17,3.5 L17,14.5 C17,16 16,17 14.5,17 L3.5,17 C2,17 1,16 1,14.5 L1,9 Z"
                ></path>
                <polyline points="1 9 7 14 15 4"></polyline>
              </svg>

              <span class="o-checkbox__label-value">
                Jeg accepterer
                <span class="l-checkout__terms" @click="showTerms = true"
                  >handelsbetingelserne</span
                >
              </span>
            </label>
          </div>
          <form method="post" @submit="continueToPayment()">
            <slot name="paymentform"></slot>
            <input
              type="hidden"
              name="registerUserOnOrderComplete"
              value="true"
            />

            <div class="o-form__actions" :class="{ loading: isLoading }">
              <button type="submit" class="o-btn toPayment o-btn--filled">
                Fortsæt til betaling
              </button>
              <div class="o-loaderInline" v-if="isLoading">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="l-checkout__rightpane">
      <div class="o-loader" v-if="isLoading">
        <div class="o-loader__inner">
          <div class="o-loader__spinner-wrapper">
            <div class="o-loader__spinner"></div>
          </div>
        </div>
      </div>

      <slot name="cart"></slot>
      <div class="l-checkout__discountline" v-if="cart.totalDiscount">
        <div>Rabat</div>
        <div v-html="totalDiscount"></div>
      </div>

      <div class="l-checkout__vatline">
        <div>Moms</div>
        <div v-html="totalTax"></div>
      </div>

      <div class="l-checkout__ordertotal">
        <div>Pris i alt pr. måned</div>
        <div class="value" v-html="cartTotal"></div>
      </div>
      <div class="l-checkout__periodetotal">
        <div>Total for abonnementsperioden</div>
        <div class="value" v-html="periodeTotal"></div>
      </div>
      <div class="l-checkout__tilbetaling">
        <div>Til betaling nu (VIN-opslag i prøveperiode)</div>
        <div class="value" v-html="vinPrice"></div>
      </div>

      <div class="o-form__column showMobile">
        <div class="o-form__item o-form__item--tlr">
          <div class="o-checkbox">
            <input
              type="checkbox"
              id="acceptTerms"
              name="acceptTerms"
              required="required"
              v-model="acceptTerms"
            />

            <label for="acceptTerms" class="o-checkbox__label">
              <svg width="18px" height="18px" viewbox="0 0 18 18">
                <path
                  d="M1,9 L1,3.5 C1,2 2,1 3.5,1 L14.5,1 C16,1 17,2 17,3.5 L17,14.5 C17,16 16,17 14.5,17 L3.5,17 C2,17 1,16 1,14.5 L1,9 Z"
                ></path>
                <polyline points="1 9 7 14 15 4"></polyline>
              </svg>

              <span class="o-checkbox__label-value">
                Jeg accepterer eflows
                <span @click="showTerms = true">handelsbetingelser</span>
              </span>
            </label>
          </div>
          <form method="post" @submit="continueToPayment()">
            <slot name="paymentform"></slot>
            <input
              type="hidden"
              name="registerUserOnOrderComplete"
              value="true"
            />
            <div class="o-form__actions">
              <button type="submit" class="o-btn toPayment o-btn--filled">
                Fortsæt til betaling
              </button>
              <div class="o-loaderInline" v-if="isLoading">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <slot name="cta"></slot>
    </div>
    <transition name="fade">
      <div
        class="l-checkout__box customscroll"
        v-html="terms"
        v-if="showTerms"
      ></div>
    </transition>
    <transition name="fade">
      <div
        class="l-checkout__box-bg"
        v-if="showTerms"
        @click="showTerms = false"
      ></div>
    </transition>
    <transition name="fade">
      <div
        class="l-checkout__box-close"
        @click="showTerms = false"
        v-if="showTerms"
      >
        <img src="/images/close.svg" />
      </div>
    </transition>
  </div>
</template>

<script>
import vSelect from "vue-select";
import axios from "axios";
import "vue-select/dist/vue-select.css";

export default {
  name: "checkout",
  components: {
    vSelect,
  },
  data: function () {
    return {
      isLoading: false,
      cart: this.inititalcart,
      shippingAddress: {
        businessTaxId: this.inititalcart.checkoutAddress
          ? this.inititalcart.checkoutAddress.businessTaxId
          : "",
        businessName: this.inititalcart.checkoutAddress
          ? this.inititalcart.checkoutAddress.businessName
          : "",
        address1: this.inititalcart.checkoutAddress
          ? this.inititalcart.checkoutAddress.address1
          : "",
        countryId: this.inititalcart.checkoutAddress
          ? parseInt(this.inititalcart.checkoutAddress.countryId)
          : "",
        zipCode: this.inititalcart.checkoutAddress
          ? this.inititalcart.checkoutAddress.zipCode
          : "",
        city: this.inititalcart.checkoutAddress
          ? this.inititalcart.checkoutAddress.city
          : "",
        phone: this.inititalcart.checkoutAddress
          ? this.inititalcart.checkoutAddress.phone
          : "",
        firstName: this.inititalcart.checkoutAddress
          ? this.inititalcart.checkoutAddress.firstName
          : "",
        lastName: this.inititalcart.checkoutAddress
          ? this.inititalcart.checkoutAddress.lastName
          : "",
      },
      email: this.inititalcart.email,
      couponCode: this.inititalcart.couponCode,
      couponError: null,
      showCoupon: this.inititalcart.couponCode,
      acceptTerms: false,
      storedTotalTax: 0,
      showTerms: false,
    };
  },
  props: {
    inititalcart: Object,
    countries: Object,
    csrf: String,
    terms: String,
    subscriptionInterval: Number,
    vinPrice: String,
  },
  computed: {
    totalDiscount() {
      return this.cart.totalDiscountAsCurrency.replace("kr.", "DKK");
    },
    totalTax() {
      return this.cart.totalTaxAsCurrency.replace("kr.", "DKK");
    },
    periodeTotal() {
      let total = this.subscriptionInterval * this.cart.total;

      return total + " DKK";
    },
    cartTotal() {
      return this.cart.totalAsCurrency.replace("kr.", "DKK");
    },
    enabledCountries() {
      let countries = this.countries;
      let enabledCountries = Object.keys(countries).map(function (key) {
        return { code: Number(key), label: countries[key] };
      });

      return enabledCountries;
    },
  },
  methods: {
    handleBlur() {
      for (let item in this.shippingAddress) {
        if (this.shippingAddress[item] == null) return;
      }

      if (this.email == null) return;

      //Send data to commerce/cart/update-cart
      const formData = new FormData();

      for (var key in this.shippingAddress) {
        formData.append(
          "shippingAddress[" + key + "]",
          this.shippingAddress[key]
        );
      }

      formData.append("action", "commerce/cart/update-cart");
      formData.append("email", this.email);
      formData.append("CRAFT_CSRF_TOKEN", this.csrf);
      formData.append("billingAddressSameAsShipping", 1);
      formData.append("registerUserOnOrderComplete", 1);

      this.post(formData);
    },
    applyCoupon() {
      //Send data to commerce/cart/update-cart
      const formData = new FormData();

      formData.append("couponCode", this.couponCode);
      formData.append("action", "commerce/cart/update-cart");
      formData.append("CRAFT_CSRF_TOKEN", this.csrf);

      this.post(formData);
    },
    continueToPayment() {
      if (this.isLoading) {
        return false;
      }

      for (let item in this.shippingAddress) {
        if (this.shippingAddress[item] == null) return false;
      }

      if (this.email == null) return false;

      if (!this.acceptTerms) return false;
    },
    post(formData) {
      this.isLoading = true;
      axios
        .post(document.location.href, formData)
        .then((response) => {
          this.cart = response.data.cart;
          this.isLoading = false;
        })
        .catch((error) => {});
    },
  },
  watch: {},
};
</script>

<style lang="scss">
.responsible-label {
  margin-bottom: 2px;
  margin-top: 8px;
}

.v-select {
  .vs__dropdown-toggle {
    padding: 13px 12px 11px;
    color: #0c2c52;
    font-size: 18px;
    border: 1px solid #a1b4c4;
    border-radius: 3px;
    background-color: rgba(161, 180, 196, 0.2);
  }

  .vs__clear {
    display: none;
  }
}
</style>
