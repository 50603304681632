var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "b-estimationCalculator__selectorwrapper" },
        [
          _c("h3", [_vm._v("Abonnementsperiode")]),
          _vm._v(" "),
          _c("vue-slider", {
            attrs: {
              data: _vm.sliderData,
              "data-value": "id",
              "data-label": "name",
              tooltip: "none"
            },
            scopedSlots: _vm._u([
              {
                key: "label",
                fn: function(ref) {
                  var label = ref.label
                  return [
                    _c(
                      "div",
                      {
                        class: [
                          "vue-slider-mark-label",
                          { selected: _vm.isActive(label) }
                        ]
                      },
                      [_vm._v("\n          " + _vm._s(label) + "\n        ")]
                    )
                  ]
                }
              }
            ]),
            model: {
              value: _vm.selectedPlanId,
              callback: function($$v) {
                _vm.selectedPlanId = $$v
              },
              expression: "selectedPlanId"
            }
          }),
          _vm._v(" "),
          _c("v-select", {
            attrs: {
              options: _vm.sliderData,
              label: "name",
              reduce: function(label) {
                return label.id
              },
              placeholder: "Vælg Abonnementsperiode"
            },
            model: {
              value: _vm.selectedPlanId,
              callback: function($$v) {
                _vm.selectedPlanId = $$v
              },
              expression: "selectedPlanId"
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "b-estimationCalculator__monthlyPrice" }, [
            _vm._v("\n      " + _vm._s(_vm.licensePrice()) + " DKK/md\n    ")
          ]),
          _vm._v(" "),
          _c("h3", { staticClass: "b-estimationCalculator--marginTop" }, [
            _vm._v("Beregninger pr. måned")
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "b-estimationCalculator__spinner" },
            [
              _c("label", [
                _vm._v("Antal VIN-opslag pr. måned\n        "),
                _c(
                  "span",
                  {
                    on: {
                      click: function($event) {
                        return _vm.showInfoBox()
                      }
                    }
                  },
                  [_c("img", { attrs: { src: "/images/info.svg" } })]
                )
              ]),
              _vm._v(" "),
              _c("qty-spinner", {
                attrs: { value: 50, min: 0, step: 25 },
                on: { input: _vm.changeQty },
                model: {
                  value: _vm.vinQty,
                  callback: function($$v) {
                    _vm.vinQty = $$v
                  },
                  expression: "vinQty"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "b-estimationCalculator__monthlyPrice" }, [
            _vm._v("\n      " + _vm._s(_vm.vinPrice()) + " DKK/md\n    ")
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "b-estimationCalculator__resultwrapper" }, [
        _vm._m(0),
        _vm._v(" "),
        _vm._m(1),
        _vm._v(" "),
        _vm.displayPrMonth
          ? _c("div", { staticClass: "b-estimationCalculator__price" }, [
              _vm._v("\n      " + _vm._s(_vm.monthlyPayment()) + " "),
              _c("span", [_vm._v("DKK/md.")])
            ])
          : _vm._e(),
        _vm._v(" "),
        !_vm.displayPrMonth
          ? _c("div", { staticClass: "b-estimationCalculator__price" }, [
              _vm._v("\n      " + _vm._s(_vm.calculationPrice()) + " "),
              _c("span", [_vm._v("kr./opslag")])
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "b-estimationCalculator__displaySelector" }, [
          _c("span", [_vm._v("Vis pris pr.  ")]),
          _vm._v("\n      beregning  \n      "),
          _c("label", { staticClass: "o-switch" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.displayPrMonth,
                  expression: "displayPrMonth"
                }
              ],
              attrs: { type: "checkbox" },
              domProps: {
                checked: Array.isArray(_vm.displayPrMonth)
                  ? _vm._i(_vm.displayPrMonth, null) > -1
                  : _vm.displayPrMonth
              },
              on: {
                change: function($event) {
                  var $$a = _vm.displayPrMonth,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.displayPrMonth = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.displayPrMonth = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.displayPrMonth = $$c
                  }
                }
              }
            }),
            _vm._v(" "),
            _c("span", { staticClass: "slider round" })
          ]),
          _vm._v("\n        måned\n    ")
        ]),
        _vm._v(" "),
        _c(
          "form",
          { staticClass: "add-to-cart-form", attrs: { method: "post" } },
          [
            _vm._t("default"),
            _vm._v(" "),
            _c("input", {
              attrs: {
                type: "hidden",
                name: "action",
                value: "quantity-extensions/cart/add-plan-to-cart"
              }
            }),
            _vm._v(" "),
            _c("input", { attrs: { type: "hidden", name: "qty", value: "1" } }),
            _vm._v(" "),
            _c("input", {
              attrs: {
                type: "hidden",
                name: _vm.licenseInputName(),
                value: "1"
              }
            }),
            _vm._v(" "),
            _c("input", {
              attrs: { type: "hidden", name: _vm.vinInputName() },
              domProps: { value: _vm.vinQty }
            }),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "js-purchasableId b-estimationCalculator__button",
                attrs: {
                  type: "submit",
                  name: "purchasableId",
                  value: _vm.selectedPlan.id
                }
              },
              [_vm._v("\n        Vælg pakke\n      ")]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "b-estimationCalculator__totalperiode" }, [
              _vm._v(
                "\n        Total for abonnementsperioden " +
                  _vm._s(_vm.periodePayment()) +
                  "\n        "
              ),
              _c("span", [_vm._v("DKK")])
            ])
          ],
          2
        )
      ]),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.infoboxVisible
          ? _c("div", {
              staticClass: "b-estimationCalculator__infobox-bg",
              on: {
                click: function($event) {
                  return _vm.hideInfobox()
                }
              }
            })
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.infoboxVisible
          ? _c("div", {
              staticClass: "b-estimationCalculator__infobox customscroll",
              domProps: { innerHTML: _vm._s(_vm.infotext) }
            })
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.infoboxVisible
          ? _c(
              "div",
              {
                staticClass: "l-checkout__box-close",
                on: {
                  click: function($event) {
                    _vm.infoboxVisible = false
                  }
                }
              },
              [_c("img", { attrs: { src: "/images/close.svg" } })]
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "b-estimationCalculator__trialLabelWrapper" },
      [
        _c("div", { staticClass: "b-estimationCalculator__trialLabel" }, [
          _vm._v("\n        Første 30 dage gratis\n      ")
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "b-estimationCalculator__datLogo" }, [
      _c("img", { attrs: { src: "/images/dat.svg" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }