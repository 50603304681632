<template>
	<div class="hiddenTextarea">
		<label :for="formName+'-'+handle" v-if="showTextArea">{{ fieldName }}</label>
		<textarea type="text" rows="6" :id="formName+'-'+handle" :name="handle" v-if="showTextArea"></textarea>
		<div v-if="!showTextArea" @click="showTextArea=true" class="hiddenTextarea__showArea">
		<svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
			<g id="Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
				<g id="Request-demo" transform="translate(-834.000000, -1063.000000)" fill-rule="nonzero">
					<g id="Add" transform="translate(834.000000, 1063.000000)">
						<g>
							<circle id="Oval" fill="#008AC6" cx="10" cy="10" r="10"></circle>
							<path d="M11,5 L11,9 L15,9 L15,11 L11,11 L11,15 L9,15 L9,11 L5,11 L5,9 L9,9 L9,5 L11,5 Z" id="Combined-Shape" fill="#FFFFFF"></path>
						</g>
					</g>
				</g>
			</g>
		</svg>
			{{ translations.addComment }}
		</div>
	</div>
</template>

<script>
    export default {
        name: 'hidden-textarea',
        props: {
			formName: String,
            fieldName: String,
			handle: String,
			isRequired: false,
			showTextArea: false,
			translations: null
        },

		data() {
		},

        methods: {

        },

		mounted() {

        }
    }
</script>

<style lang="scss">
	.hiddenTextarea{
		display: flex;
    	flex-direction: column;

		&__showArea{
			margin-top: 8px;
			display: flex;
			align-items: center;
			color: #008AC6;
			font-size: 14px;
			font-weight: bold;
			letter-spacing: 0;
			line-height: 21px;
			transition: all ease .3s;
			&:hover{
				cursor: pointer;
				color: #0C2C52;
			}

			svg{
				margin-right: 6px;
			}
		}
	}
</style>
