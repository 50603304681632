var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "hiddenTextarea" }, [
    _vm.showTextArea
      ? _c("label", { attrs: { for: _vm.formName + "-" + _vm.handle } }, [
          _vm._v(_vm._s(_vm.fieldName))
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.showTextArea
      ? _c("textarea", {
          attrs: {
            type: "text",
            rows: "6",
            id: _vm.formName + "-" + _vm.handle,
            name: _vm.handle
          }
        })
      : _vm._e(),
    _vm._v(" "),
    !_vm.showTextArea
      ? _c(
          "div",
          {
            staticClass: "hiddenTextarea__showArea",
            on: {
              click: function($event) {
                _vm.showTextArea = true
              }
            }
          },
          [
            _c(
              "svg",
              {
                attrs: {
                  width: "20px",
                  height: "20px",
                  viewBox: "0 0 20 20",
                  version: "1.1",
                  xmlns: "http://www.w3.org/2000/svg",
                  "xmlns:xlink": "http://www.w3.org/1999/xlink"
                }
              },
              [
                _c(
                  "g",
                  {
                    attrs: {
                      id: "Desktop",
                      stroke: "none",
                      "stroke-width": "1",
                      fill: "none",
                      "fill-rule": "evenodd"
                    }
                  },
                  [
                    _c(
                      "g",
                      {
                        attrs: {
                          id: "Request-demo",
                          transform: "translate(-834.000000, -1063.000000)",
                          "fill-rule": "nonzero"
                        }
                      },
                      [
                        _c(
                          "g",
                          {
                            attrs: {
                              id: "Add",
                              transform: "translate(834.000000, 1063.000000)"
                            }
                          },
                          [
                            _c("g", [
                              _c("circle", {
                                attrs: {
                                  id: "Oval",
                                  fill: "#008AC6",
                                  cx: "10",
                                  cy: "10",
                                  r: "10"
                                }
                              }),
                              _vm._v(" "),
                              _c("path", {
                                attrs: {
                                  d:
                                    "M11,5 L11,9 L15,9 L15,11 L11,11 L11,15 L9,15 L9,11 L5,11 L5,9 L9,9 L9,5 L11,5 Z",
                                  id: "Combined-Shape",
                                  fill: "#FFFFFF"
                                }
                              })
                            ])
                          ]
                        )
                      ]
                    )
                  ]
                )
              ]
            ),
            _vm._v("\n\t\t" + _vm._s(_vm.translations.addComment) + "\n\t")
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }