<template>
	<div class="qd-cookie-popup" v-if="showPopup">
		<div class="qd-cookie-popup__inner">
			<strong>
				{{translations.Headline}}
			</strong>

			<p> {{translations.Text}} </p>
			<p>
				<a class="link" :href="cookiePageUrl">
					{{translations.ReadMore}}
				</a>
			</p>

			<div class="qd-cookie-popup__action">
				<a href="#" class="o-btn o-btn--filled js-qd-cookie-popup-accept" v-on:click="acceptCookies"> {{translations.Accept}} </a>
				<a href="#" class="o-btn js-qd-cookie-popup-deny" v-on:click="declineCookies"> {{translations.Deny}} </a>
			</div>
		</div>
	</div>
</template>

<script>
    export default {
        name: 'cookie-popup',
        props: {
            cookiePageUrl: String,
			keyName: {
				type: String,
				default: 'cookieConsent'
			},
			maxAge: 31536000,
            translations: {
                type: Object,
                required: true
            },
        },

		data() {
            return {
          		showPopup: false,
                pageviewAlreadySent: false
			}
		},

        methods: {
            acceptCookies: function (responder) {
                responder.preventDefault();
                this.showPopup = false
                this.setConsent(true, true)
            },
            declineCookies: function (responder) {
                responder.preventDefault();
                this.showPopup = false
                this.setConsent(false, true)
            },
            setConsent: function (consentState, persist) {
                var dataLayerVariable = {};

                if ( consentState === true && !this.pageviewAlreadySent ) {
                    dataLayerVariable.event = 'consentPageview';
                    this.pageviewAlreadySent = true;
                }

                // Set consent variable
                dataLayerVariable[ this.keyName ] = !!consentState;

                if (typeof(dataLayer) != "undefined"){
                    dataLayer.push(dataLayerVariable);
                }

                // Set the popup state
                if(consentState === null){
                    this.showPopup = true
                }


                if ( persist && consentState !== null ) {
                    this.set( this.keyName, consentState );
                } else if ( consentState === null ) {
                    this.remove( this.keyName );
                }
            },
            get: function (keyName) {
                if ( localStorage ) {
                    var value = localStorage.getItem( keyName );
                    return value !== null ? JSON.parse( value ) : null;
                } else {
                    var match = document.cookie.match(new RegExp('(^| )' + keyName + '=([^;]+)'));
                    return match ? JSON.parse( match[ 2 ] ) : null;
                }
            },
            set: function (keyName,value) {
                if ( localStorage ) {
                    localStorage.setItem( keyName, value );
                } else {
                    document.cookie = keyName + '=' + value + ';max-age=' + this.maxAge;
                }
            },
            remove: function (keyName) {
                if ( localStorage ) {
                    localStorage.removeItem( keyName );
                } else {
                    document.cookie = keyName + '=; Max-Age=-99999999;';
                }
            }
        },

		mounted() {
			this.setConsent(this.get(this.keyName),true)

            const links = document.querySelectorAll('[href="#reset-cookies"]');
            links.forEach((link) =>
                link.addEventListener("click", (e) => {
                    e.preventDefault();
                    this.setConsent(null);
                })
            );
        }
    }
</script>
