<template>
	<transition name='fade' @after-enter="showClose = true" @after-leave="showClose = false">
		<div class="image-popup" v-if="show" v-on:click="close()">
			<transition name='close'>
				<div class="image-popup__close" v-if="showClose" v-on:click="close()">
					<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
						<g fill="#DE3C4B" fill-rule="evenodd">
							<rect width="28" height="2" y="13" rx="1" transform="rotate(45 14 14)"/>
							<rect width="28" height="2" y="13" rx="1" transform="rotate(135 14 14)"/>
						</g>
					</svg>
				</div>
			</transition>
			<div class="image-popup__img">
				<img :src="image" />
			</div>
		</div>
	</transition>
</template>

<script>

    export default {
        name: 'image-popup',
        props: {
            image: {
                type: String,
                default: '',
            }
        },

        data: () => ({
            showClose: false,
            show: false
        }),

        mounted() {
            this.show = true
        },

        methods: {
            close() {
                this.$store.commit('setImagePopupState', false)
            }
        }
    }
</script>

<style lang="scss">
	.image-popup {
		position: fixed;
		z-index: 50;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		background: rgba(12, 44, 82, 0.75);
		transition: opacity .6s;
		opacity: 1;

		&__close {
			z-index: 1000;
			background-color: #fff;
			top: 0;
			right: 0;
			position: absolute;
			width: 80px;
			height: 80px;
			display: flex;
			justify-content: center;
			align-items: center;
			opacity: 1;
			transition: all ease .3s;

			svg {
				g {
					fill: #E0AE42;
				}
			}

			&.close-enter {
				opacity: 0;
				right: -80px;
			}

			&:hover {
				cursor: pointer;
				opacity: .7;
			}
		}

		&__img {
			margin: 120px;
			max-height: calc(100vh - 240px);
			max-width: calc(100vw - 240px);
			background: #fff;
			padding: 29px 29px;
			border-radius: 3px;
			font-size: 0;
			line-height: 0;

			img{
				max-width: calc(100vw - 240px - 58px);
				max-height: calc(100vh - 240px - 58px);
			}

			@media screen and (max-width: 999px){
				margin: 45px;
				max-height: calc(100vh - 90px);
				max-width: calc(100vw - 90px);
				img{
					max-width: calc(100vw - 90px - 58px);
					max-height: calc(100vh - 90px - 58px);
				}
			}

			@media screen and (max-width: 559px){
				margin: 22px;
				max-height: calc(100vh - 44px);
				max-width: calc(100vw - 44px);
				img{
					max-width: calc(100vw - 44px - 58px);
					max-height: calc(100vh - 44px - 58px);
				}
			}
		}

	}
</style>
