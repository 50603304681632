<template>
  <div class="faqslist">
    <div class="faqslist__header">
      <h2>{{ title }}</h2>
      <div class="faqslist__filter">
        <div
          class="faqslist__filter-item"
          :class="{ active: null == selectedSolution }"
          v-on:click="selectedSolution = null"
        >
          {{ translations.all }}
        </div>
        <div
          class="faqslist__filter-item"
          :class="{ active: 'workflow' == selectedSolution }"
          v-on:click="selectedSolution = 'workflow'"
        >
          {{ "Workflow" }}
        </div>
        <div
          class="faqslist__filter-item faqslist__filter-item_inventory"
          :class="{ active: 'inventory' == selectedSolution }"
          v-on:click="selectedSolution = 'inventory'"
        >
          {{ "Inventory" }}
        </div>
      </div>
    </div>
    <div class="o-loader" v-if="isLoading">
      <div class="o-loader__inner">
        <div class="o-loader__spinner-wrapper">
          <div class="o-loader__spinner"></div>
        </div>
      </div>
    </div>

    <article>
      <a
        :href="faq.url"
        class="faqslist__item"
        :key="index"
        v-for="(faq, index) in filteredFaqs"
      >
        <div class="faqslist__item-title">
          {{ faq.title }}
          <span
            class="faqslist__item-label"
            :class="'faqslist__item-label_' + faq.solution"
            v-if="faq.solution"
            >{{ faq.solution | capitalize }}</span
          >
        </div>

        <div class="faqslist__item-link">{{ label }}</div>
      </a>
    </article>

    <a
      class="o-btn o-btn--filled faqslist__loadmore"
      @click="loadMore"
      v-if="ifMoreToLoad"
      >{{ button }}</a
    >
  </div>
</template>

<script>
import vSelect from "vue-select";
import gql from "graphql-tag";

export default {
  name: "faq-list",

  components: {
    vSelect,
  },

  props: {
    label: {
      type: String,
    },
    title: {
      type: String,
    },
    button: {
      type: String,
    },
    siteId: {
      type: String,
      required: true,
    },
    translations: {
      type: Object,
      required: true,
    },
    apolloData: [],
  },

  apollo: {
    entries: {
      query: gql`
        query GetFaqs($siteId: [QueryArgument]) {
          entries(section: "faqs", orderBy: "title", siteId: $siteId) {
            title
            url
            ... on faqs_faq_Entry {
              solution
            }
          }
        }
      `,
      variables() {
        return { siteId: parseInt(this.siteId) };
      },
      result({ data, loading, networkStatus }) {
        if (!loading) {
          let faqs = [];

          Array.from(this.entries).forEach(function (faq) {
            faqs.push(faq);
          });

          this.faqs = faqs;
          this.isLoading = false;
        }
      },
    },
  },

  data: () => ({
    isLoading: true,
    limit: 4,
    filtered: 0,
    faqs: [],
    entries: [],
    selectedSolution: null,
  }),

  computed: {
    filteredFaqs(value) {
      let faqs = this.faqs;

      if (this.selectedSolution) {
        faqs = faqs.filter((el) => {
          return el.solution === this.selectedSolution;
        });
      }

      this.filtered = faqs.length;

      return this.limit ? faqs.slice(0, this.limit) : faqs;
    },
    ifMoreToLoad() {
      if (this.limit >= this.filtered) {
        return false;
      }

      return true;
    },
  },

  methods: {
    loadMore: function () {
      this.limit = this.limit + 4;
    },
  },

  mounted() {},

  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },
};
</script>

<style lang="scss">
.faqslist {
  position: relative;
  text-align: center;

  h2 {
    text-align: left;
    margin-bottom: 0;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 42px;
  }

  &__filter {
    display: flex;
    &-item {
      margin-left: 10px;
      line-height: 14px;
      font-size: 12px;
      border: 1px solid #008ac6;
      color: #0c2c52;
      padding: 4px 10px;
      border-radius: 3px;
      transition: all ease 0.3s;
      &:hover,
      &.active {
        cursor: pointer;
        color: #fff;
        background: #008ac6;
      }
    }
  }

  &__item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3px;
    padding: 27px 35px 31px;
    border: 1px solid #a5bbc8;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.07);
    text-align: left;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 21px;

    &-title {
      display: flex;
      align-items: baseline;
    }

    &-label {
      margin-left: 10px;
      line-height: 14px;
      font-size: 12px;
      background: #008ac6;
      color: #fff;
      padding: 5px 10px 4px;
      border-radius: 3px;
      &_inventory {
        background: rgb(244, 189, 72);
      }
    }

    &-link {
      color: #008ac6;
      text-decoration: underline;
    }
  }

  &__loadmore {
    margin-top: 25px;

    &:hover {
      cursor: pointer;
    }
  }
}
</style>
