
<template>
  <div>
    <div class="b-estimationCalculator__selectorwrapper">
      <h3>Abonnementsperiode</h3>

      <vue-slider
        v-model="selectedPlanId"
        :data="sliderData"
        :data-value="'id'"
        :data-label="'name'"
        :tooltip="'none'"
      >
        <template v-slot:label="{ label }">
          <div
            :class="['vue-slider-mark-label', { selected: isActive(label) }]"
          >
            {{ label }}
          </div>
        </template>
      </vue-slider>

      <v-select
        :options="sliderData"
        v-model="selectedPlanId"
        label="name"
        :reduce="(label) => label.id"
        placeholder="Vælg Abonnementsperiode"
      ></v-select>

      <div class="b-estimationCalculator__monthlyPrice">
        {{ licensePrice() }} DKK/md
      </div>

      <h3 class="b-estimationCalculator--marginTop">Beregninger pr. måned</h3>

      <div class="b-estimationCalculator__spinner">
        <label
          >Antal VIN-opslag pr. måned
          <span @click="showInfoBox()"><img src="/images/info.svg" /></span
        ></label>
        <qty-spinner
          :value="50"
          :min="0"
          :step="25"
          @input="changeQty"
          v-model="vinQty"
        ></qty-spinner>
      </div>
      <div class="b-estimationCalculator__monthlyPrice">
        {{ vinPrice() }} DKK/md
      </div>
    </div>

    <div class="b-estimationCalculator__resultwrapper">
      <div class="b-estimationCalculator__trialLabelWrapper">
        <div class="b-estimationCalculator__trialLabel">
          Første 30 dage gratis
        </div>
      </div>

      <div class="b-estimationCalculator__datLogo">
        <img src="/images/dat.svg" />
      </div>

      <div class="b-estimationCalculator__price" v-if="displayPrMonth">
        {{ monthlyPayment() }} <span>DKK/md.</span>
      </div>

      <div class="b-estimationCalculator__price" v-if="!displayPrMonth">
        {{ calculationPrice() }} <span>kr./opslag</span>
      </div>

      <div class="b-estimationCalculator__displaySelector">
        <span>Vis pris pr. &nbsp;</span>
        beregning &nbsp;
        <label class="o-switch">
          <input type="checkbox" v-model="displayPrMonth" />
          <span class="slider round"></span>
        </label>
        &nbsp; måned
      </div>

      <form method="post" class="add-to-cart-form">
        <slot></slot>
        <input
          type="hidden"
          name="action"
          value="quantity-extensions/cart/add-plan-to-cart"
        />

        <input type="hidden" name="qty" value="1" />
        <input type="hidden" :name="licenseInputName()" value="1" />
        <input type="hidden" :name="vinInputName()" :value="vinQty" />

        <button
          class="js-purchasableId b-estimationCalculator__button"
          type="submit"
          name="purchasableId"
          :value="selectedPlan.id"
        >
          Vælg pakke
        </button>
        <div class="b-estimationCalculator__totalperiode">
          Total for abonnementsperioden {{ periodePayment() }}
          <span>DKK</span>
        </div>
      </form>
    </div>

    <transition name="fade">
      <div
        class="b-estimationCalculator__infobox-bg"
        v-if="infoboxVisible"
        @click="hideInfobox()"
      ></div>
    </transition>
    <transition name="fade">
      <div
        v-if="infoboxVisible"
        class="b-estimationCalculator__infobox customscroll"
        v-html="infotext"
      ></div>
    </transition>

    <transition name="fade">
      <div
        class="l-checkout__box-close"
        @click="infoboxVisible = false"
        v-if="infoboxVisible"
      >
        <img src="/images/close.svg" />
      </div>
    </transition>
  </div>
</template>

<script>
import QtySpinner from "./QtySpinner.vue";
import VueSlider from "vue-slider-component";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  name: "estimation-calculator",
  components: {
    QtySpinner,
    VueSlider,
    vSelect,
  },
  data: function () {
    return {
      vinQty: 50,
      selectedPlanId: this.planId,
      selectedLicense: Array,
      selectedVin: Array,
      displayPrMonth: true,
      infoboxVisible: false,
    };
  },
  props: {
    plans: Array,
    products: Array,
    currency: String,
    infotext: String,
    planId: Number,
  },
  computed: {
    sliderData() {
      let data = [];
      for (var i = 0; i < this.plans.length; i++) {
        let plan = {
          id: this.plans[i].id,
          name: this.plans[i].title,
        };
        data.push(plan);
      }

      return data;
    },
    selectedPlan() {
      let plan = this.plans.filter((p) => p["id"] == this.selectedPlanId);
      return plan[0];
    },
  },
  methods: {
    showInfoBox() {
      this.infoboxVisible = true;
    },
    hideInfobox() {
      this.infoboxVisible = false;
    },
    isActive(label) {
      return this.selectedPlan.title === label;
    },
    changeQty(value) {
      this.vinQty = value;
    },
    changePlan(value) {
      this.selectedPlan = value.value;
    },
    sliderMarks() {
      let data = [];
      for (var i = 0; i < this.plans.length; i++) {
        let plan = {
          label: this.plans[i].title,
        };
        data.push(plan);
      }

      return data;
    },
    licenseInputName() {
      let purchasables = this.selectedPlan.purchasables;
      let products = this.products;

      for (var i = 0; i < purchasables.length; i++) {
        let purchasableId = purchasables[i].productId;
        let product = products.filter((p) => p["id"] == purchasableId);
        if (product[0].type === "license") {
          this.selectedLicense = product[0];
          return "options[qty][" + purchasables[i].id + "]";
        }
      }
    },
    vinInputName() {
      let purchasables = this.selectedPlan.purchasables;
      let products = this.products;

      for (var i = 0; i < purchasables.length; i++) {
        let purchasableId = purchasables[i].productId;
        let product = products.filter((p) => p["id"] == purchasableId);
        if (product[0].type === "vin") {
          this.selectedVin = product[0];
          return "options[qty][" + purchasables[i].id + "]";
        }
      }
    },
    vinPrice() {
      let floatVal = this.selectedVin.price * this.vinQty;

      if (floatVal % 1 !== 0) {
        // Cast to string and return the last two numbers.
        floatVal = parseFloat(
          (this.selectedVin.price * this.vinQty).toFixed(2)
        );
      }

      return floatVal;
    },
    licensePrice() {
      return this.selectedLicense.price / 1;
    },
    monthlyPayment() {
      let monthlyPayment = this.licensePrice() + parseFloat(this.vinPrice());
      return monthlyPayment.toFixed(2);
    },
    periodePayment() {
      let monthlyPayment = this.licensePrice() + parseFloat(this.vinPrice());
      let periodePayment =
        monthlyPayment * parseInt(this.selectedPlan.subscriptionInterval);
      return periodePayment.toFixed(2);
    },
    calculationPrice() {
      let price = this.monthlyPayment() / this.vinQty;
      return price.toFixed(2);
    },
  },
  watch: {},
};
</script>

<style lang="scss">
.b-estimationCalculator__displaySelector .o-switch .slider {
  background: #00a783;
}
</style>
